import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import { Col, Row } from 'reactstrap';


//const useStyles = makeStyles((theme) => ({
//    formControl: {
//        margin: theme.spacing(1),
//        minWidth: 120,
//    },
//    selectEmpty: {
//        marginTop: theme.spacing(2),
//    },
//}));


export class SettingsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: this.props.handleOpen,
            galleryProps: {
                slideInterval: 3000,
                backdropClosesModal: false,
                enableLightbox: true,
                imageCountSeparator: ' / ',
                enableImageSelection: false,
                enableKeyboardInput: true,
                showLightboxThumbnails: false,
                rowHeight: 150,
                lightboxWidth: 1200,
            }
        };
        this.toggleChecked.bind(this);
        
    }



    saveSettings() {
        localStorage.setItem('galleryProps', JSON.stringify(this.state.galleryProps))
    }


    //handleClickOpen() {
    //    this.setState({ open: true });
    //}

    handleClose() {
        this.props.handleCancel();
    }

    handleSubmit() {
        this.saveSettings();
        this.setState({ open: false });
        this.props.handleSubmit();
    }

    toggleChecked(obj) {
        this.setState({
            galleryProps: {
                ...this.state.galleryProps,
                [obj.currentTarget.value]: obj.currentTarget.checked
            }
        });

        /*switch (obj.target.value) {
            case "backdropClosesModal":
                this.galleryProps.backdropClosesModal = obj.target.checked;
                break;
            case "enableKeyboardInput":
                this.galleryProps.enableKeyboardInput = obj.target.checked;
                break;
            case 'showLightboxThumbnails':
                this.galleryProps.showLightboxThumbnails = obj.target.checked;
                break;
            default:
                break;
        }
        */
    }

    componentDidMount() {
        const myProps = localStorage.getItem('galleryProps');
        if (myProps) {

            this.setState({ galleryProps: JSON.parse(myProps) });
        }
    }


    intervalOnChange(obj) {
        this.setState({
            galleryProps: {
                ...this.state.galleryProps,
                slideInterval: parseInt(obj.target.value)
            }
        });
    }

    imageCountSeparatorOnChange(obj) {
        this.setState({
            galleryProps: {
                ...this.state.galleryProps,
                imageCountSeparator: obj.target.value
            }
        });

    }

    render() {
        return (
            <>
                <Dialog
                    open={this.props.handleOpen}
                    onClose={this.handleClose}
                    maxWidth="sm"
                    fullWidth={true}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">ATilføj</DialogTitle>
                    <DialogContent>
                        <DialogContentText>

                        </DialogContentText>
                        {/* backdropClosesModal */}
                        <Row>
                            <Col xs="12">
                                <FormControl>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                value="backdropClosesModal"
                                                checked={this.state.galleryProps.backdropClosesModal}
                                                inputProps={{ 'aria-label': 'backdropClosesModal' }}
                                                onChange={this.toggleChecked.bind(this)}
                                                color="primary"
                                            />
                                        }
                                        label="Klik på baggrund lukker galleri"
                                    />

                                </FormControl></Col>
                        </Row>
                        {/* enableKeyboardInput */}
                        <Row>
                            <Col xs="12">
                                <FormControl>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                value="enableKeyboardInput"
                                                checked={this.state.galleryProps.enableKeyboardInput}
                                                inputProps={{ 'aria-label': 'enableKeyboardInput' }}

                                                onChange={this.toggleChecked.bind(this)}
                                                color="primary"
                                            />
                                        }
                                        label="Tillad tastatur styring"
                                    />

                                </FormControl>
                            </Col>
                        </Row>
                        {/* showLightboxThumbnails */}
                        <Row>
                            <Col xs="12">
                                <FormControl>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                value="showLightboxThumbnails"
                                                checked={this.state.galleryProps.showLightboxThumbnails}
                                                inputProps={{ 'aria-label': 'showLightboxThumbnails' }}

                                                onChange={this.toggleChecked.bind(this)}
                                                color="primary"
                                            />
                                        }
                                        label="Vis minuaturer"
                                    />

                                </FormControl>
                            </Col>
                        </Row>
                        {/* SlideInterval */}
                        <Row>
                            <Col xs="12">
                                <FormControl>
                                    <FormControlLabel
                                        control={
                                            <>
                                                <InputLabel htmlFor="slideInterval">Autoplay interval</InputLabel>
                                                <Select
                                                    style={{ minWidth: 150 }}
                                                    value={this.state.galleryProps.slideInterval}
                                                    onChange={this.intervalOnChange.bind(this)}
                                                    inputProps={{
                                                        name: 'slideInterval',
                                                        id: 'slideInterval',
                                                    }}
                                                >
                                                    <option value="1000">1 sek.</option>
                                                    <option value="3000">3 sek.</option>
                                                    <option value="5000">5 sek.</option>
                                                    <option value="10000">10 sek.</option>
                                                </Select></>
                                        }

                                    />

                                            }

                                </FormControl>
                            </Col>
                        </Row>
                        {/* imageCountSeparator */}
                        <Row>
                            <Col xs="12">
                                <FormControl>
                                    <FormControlLabel
                                        control={
                                            <TextField id="imageCountSeparator" onChange={this.imageCountSeparatorOnChange.bind(this)} label="Index separator " value={this.state.galleryProps.imageCountSeparator} />

                                        } />

                                </FormControl>
                            </Col>
                        </Row>


                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose.bind(this)} color="default">
                            Annuller</Button>
                        <Button onClick={this.handleSubmit.bind(this)} color="primary">
                            Gem</Button>
                    </DialogActions>

                </Dialog>
            </>
        )
    }
}