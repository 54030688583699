import React, { Component } from 'react';
import Moment from 'react-moment';
import 'moment/locale/da';
import { Col, Row } from 'react-bootstrap';
import './Show.css';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Galleries from '../data/Gallery.json';

export class Vis extends Component {
    static displayName = Vis.name;

    constructor(props) {
        super(props);
        this.state = {
            folder: props.match.params.path,
            gallery: Galleries.filter(function (item) { return item.Folder === props.match.params.path ; }),
            loading: true,
            images: [],
            showThumbnails: false,
            showBullets: false,
            thumbnailPosition: 'left',
            showFullscreenButton: true,
            showGalleryFullscreenButton: true,
            showPlayButton: true,
            showGalleryPlayButton: true,
            showNav: true,
        };
    }

    componentDidMount() {
        this.populateGalleryData();
    }


    //_handleCheckboxChange(state, event) {
    //    this.setState({ [state]: event.target.checked });
    //}

    //_handleThumbnailPositionChange(event) {
    //    this.setState({ thumbnailPosition: event.target.value });
    //}

    render() {

        let contents = this.state.loading
            ? <div>


                <p><em>  <FontAwesomeIcon icon={faSpinner} size="2x" spin={true} title="Henter fotos" />&nbsp;Henter fotos...</em></p>

            </div>
            :
            <Row>
                <Col lg="12"> <h4> <span style={{ float: 'right' }}>
                    <small>
                        <Moment
                            locale="da"
                            format="D. MMMM YYYY">{this.state.gallery[0].Date}</Moment>
                    </small></span>
                    <Col lg="12" dangerouslySetInnerHTML={{ __html: this.state.gallery[0].Title }}></Col></h4>
                    <Col lg="12" className="text-center" dangerouslySetInnerHTML={{ __html: this.state.gallery[0].Description }}></Col>

                </Col>
                <Col lg="12">


                    <Carousel>
                        {this.state.images.map(img =>
                            <img className="img-fluid" src={img.original} title={img.originalTitle} alt={img.originalTitle} />
                        )}
                        </Carousel>

                </Col>
            </Row>
        // Fotos.renderGallery(this.state.images, this.state.gallery, this.state);


        return (
            <div>
                {contents}

            </div>
        );
    }

    async populateGalleryData() {
        const response = await fetch('api/Gallery/getFotos/' + this.state.folder);
        const data = await response.json();
        this.setState({ images: data, loading: false });

    }
}
