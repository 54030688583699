import React, { Component } from 'react';
import 'react-image-gallery/styles/css/image-gallery.css';
import { Link } from "react-router-dom";
import { Card, CardImg, CardDeck, CardTitle, Col, Row, CardText } from 'reactstrap';
import './Card/Card.css';
import Moment from 'react-moment';
import 'moment/locale/da';
import Galleries from '../data/Gallery.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
export class Home extends Component {
    static displayName = Home.name;
    constructor(props) {
        super(props);

        if (!localStorage.getItem("sortOrder"))
            localStorage.setItem("sortOrder", 'date_des');

        this.state = {
            galleries: [],
            loading: false,
            sortOrder: localStorage.getItem("sortOrder")
        };


    }

    componentDidMount() {
        //this.populateGalleryData();
        var sortedData;
        sortedData = this._getSortedData(Galleries, this.state.sortOrder)
        this.setState({ galleries: sortedData, loading: false });
        this.renderImageGallery(sortedData);
    }

    componentWillUpdate(nextProp, NextState) {

    }

    _handleSortOrderChange(event) {
        //Get the sorted data
        var sortedData = this._getSortedData(this.state.galleries, event.target.value);

        //Store sortOrder in LocaleStorage
        localStorage.setItem("sortOrder", event.target.value);

        //Set state
        this.setState({ sortOrder: event.target.value, galleries: sortedData, });
    }

    _getSortedData(data, sortBy) {
        var sortJsonArray = require('sort-json-array');
        var sortOrder = { orderBy: '', direction: '' };
        var sortedData;
        switch (sortBy) {
            case 'date_asc':
                sortOrder.orderBy = "Date";
                sortOrder.direction = "asc";
                break;
            case 'alfa_des':
                sortOrder.orderBy = "Title";
                sortOrder.direction = "des";
                break;
            case 'alfa_asc':
                sortOrder.orderBy = "Title";
                sortOrder.direction = "asc";
                break;
            default:
                sortOrder.orderBy = "Date";
                sortOrder.direction = "des";
                break;
        }

        sortedData = sortJsonArray(data, sortOrder.orderBy, sortOrder.direction);
        this.setState({ sortOrder: sortBy, galleries: sortedData, });


        return sortedData;
    }


    renderImageGallery = (galleries) => {

        return (
            <Row>

                {galleries.map(gallery =>

                    <Col sm="12" md="6" lg="3" key={gallery.Title}>
                        <CardDeck>
                            <Card className="box-shadow category-card" >
                                <Link
                                    to={{ pathname: '/showGrid/' + gallery.Folder, state: { Gallery: gallery } }}
                                    title={gallery.Description}>
                                    <CardTitle><div dangerouslySetInnerHTML={{ __html: gallery.Title }}></div></CardTitle>
                                    <CardImg
                                        bottom
                                        src={gallery.Image != null && gallery.Image.length > 0
                                            ?
                                            gallery.Image
                                            :
                                            ''}
                                        rounded="true"
                                        responsive="true"
                                        width="100%"
                                        title={gallery.Description}
                                        alt={gallery.Title} />

                                    <CardText>
                                        <Moment
                                            locale="da"
                                            format="D. MMMM YYYY">{gallery.Date}</Moment>

                                        <br /><small> {gallery.Count != null ? '(' + gallery.Count + ' Fotos.)' : ''}</small>
                                    </CardText>
                                </Link>
                            </Card>
                        </CardDeck>
                    </Col>
                )
                }
            </Row>
        )
    }

    render() {

        let contents = this.state.loading
            ? <p><em>  <FontAwesomeIcon icon={faSpinner} size="2x" spin={true} title="Henter fotos" />&nbsp;Henter...</em></p>

            :
            this.renderImageGallery(this.state.galleries);

        return (<div>
            <Row>
                <Col lg="12">
                    <h4>Velkommen</h4>
                    <div className='app-interval-input-group  float-right'>
                        <span className='app-interval-label'>Sortering:</span>
                        <select
                            className='app-interval-input'
                            value={this.state.sortOrder}
                            onChange={this._handleSortOrderChange.bind(this)}
                        >
                            <option value='date_des'>Dato nyeste først</option>
                            <option value='date_asc'>Dato ældste først</option>
                            <option value='alfa_asc'>Title a - å</option>
                            <option value='alfa_des'>Title å - a </option>
                        </select>
                    </div>
                    <p>Her kan du se et udvalg af mine fotos</p>
                    <p></p>
                    <p>Med venlig hilsen<br />
                    Erik Lyngsøe</p>
                </Col>
            </Row>
            {contents}
        </div>
        );
    }




    async populateGalleryData() {
        const response = await fetch('/data/Gallery.json');
        const data = await response.json();

        var sortedData = this._getSortedData(data, this.state.sortOrder)
        this.setState({ galleries: sortedData, loading: false });

    }

} 