import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { Link } from 'react-router-dom';
//import { SettingsMenu, SettngsModal } from './Settings/SettngsModal';

//import { parseTwoDigitYear } from 'moment';
import './Layout.css'

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div>
                <NavMenu />
                <Container style={{ marginTop: '75px', marginBottom: '70px' }}>
                    {this.props.children}
                </Container>
                
                <div id="footer" className="footer box-shadow">
                    © 2021, Erik Lyngsøe. All rigths reserved.<br />

                    <Link to='/'>ungstrup.net</Link>&nbsp;·&nbsp;Ungstrupvej 1A&nbsp;·&nbsp;Ungstrup&nbsp;·&nbsp;8620 Kjellerup </div>

                      
            </div>
        );
    }
}

