import React, { Component } from 'react';
import './Gear.css'
export class Gear extends Component {
    static displayName = Gear.name;




    render() {
        return (
            <div>
                <h5>Mit gear</h5>
                <ul id="myGear">
                    <li><a href="https://www.canon.dk/cameras/eos-r6/" target="_blank"  rel="noopener noreferrer" >Canon EOS R6</a></li>
                    <li><a href="https://www.canon.dk/lenses/ef-50mm-f-1-4-usm-lens/" target="_blank" rel="noopener noreferrer" >Canon EF 50mm f/1.4 USM</a></li>
                    <li><a href="https://www.canon.dk/lenses/rf-24-240mm-f4-6-3-is-usm/" target="_blank">Canon RF 24-240MM F4-6.3 IS USM</a></li>
                    <li><a href="https://www.sigma-global.com/en/lenses/discontinued/tele/150_500_5_63_os/" target="_blank"  rel="noopener noreferrer" >Sigma APO 150-500mm F5-6.3 DG OS HSM</a></li>
                </ul>
                <h5>Primær Software</h5>
                <ul id="mySoftware">
                    <li><a href="https://visualstudio.microsoft.com/downloads/" target="_blank"  rel="noopener noreferrer" >Visual Studio 2019</a></li>
                    <li><a href="https://www.mysql.com/products/workbench/" target="_blank"  rel="noopener noreferrer" >MySql WorkBench</a></li>
                    <li><a href="https://www.adobe.com/dk/products/photoshop.html" target="_blank"  rel="noopener noreferrer" >Adobe photoshop 2020</a></li>
                    <li><a href="https://www.adobe.com/dk/products/photoshop-lightroom.html?promoid=RL89NGLD&mv=other" target="_blank"  rel="noopener noreferrer" >Adobe Ligthroom</a></li>
                    <li><a href="https://www.office.com/" target="_blank"  rel="noopener noreferrer" >Office 365 pakken</a></li>
                </ul>
                <h5>PC</h5>
                <ul id="myPC">
                    <li>
                        MB: <a href="https://asrock.com/mb/AMD/B450M%20Steel%20Legend/index.asp" taget="_blank">AsRock B450M Steel Legend</a>
                    </li>
                    <li>CPU: <a href="https://www.amd.com/en/processors/ryzen-9" target="_blank"  rel="noopener noreferrer" >AMD Ryzen 9 Series 3900X 12/24 Cores/threads</a></li>
                    <li>RAM: 32 GB DDR4 2666 mHz</li>
                    <li>GPU: <a href="https://www.techpowerup.com/gpu-specs/geforce-gt-710.c1990" target="_blank"  rel="noopener noreferrer" >Nvidia GForce GT 710 2 Gb ram</a></li>
                    <li>1. HD: <a href="https://www.pny.com/CS3030-M-2-NVMe-SSD?sku=M280CS3030-500-RB&CURRENT_NAV_ID=b83c9ac0-e251-454c-b078-3bbd37ce1ff5" target="_blank"  rel="noopener noreferrer" >500 gb NVMe PNY CS3030 500GB SSD - PCI x 3  - 3500 MBps (læs) / 2000 MBps (skriv)</a></li>
                </ul>

            </div>
        );
    }
}
