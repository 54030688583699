import React from 'react';
import Gallery from 'react-grid-gallery';
import Moment from 'react-moment';
import 'moment/locale/da';
import Galleries from '../../data/Gallery.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faExpand, faCompress, faCog } from '@fortawesome/free-solid-svg-icons';
import FullScreen from 'react-request-fullscreen';
import { SettingsModal } from '../Settings/SettingsModal'

export class ShowGrid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            folder: props.match.params.path,
            gallery: Galleries.filter(function (item) { return item.Folder === props.match.params.path; }),
            images: [],
            currentImage: 0,
            isPlaying: false,
            loading: true,
            isFullScreen: false,
            timerId: 0,
            slideInterval: 3000,
            isModalOpen: false,
            galleryProps: {
                backdropClosesModal: false,
                enableLightbox: true,
                imageCountSeparator: ' / ',
                enableImageSelection: false,
                enableKeyboardInput: true,
                showLightboxThumbnails: false,
                rowHeight: 150,
                lightboxWidth: 1200,
            }
        };

        this.onCurrentImageChange = this.onCurrentImageChange.bind(this);
        this.onAutoPlay = this.onAutoPlay.bind(this);
        this.toggle = this.toggle.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.cancelModal = this.cancelModal.bind(this);
    }

    toggle() {
        this.setState({
            isModalOpen: !this.state.isModalOpen
        });
    }

    showModal() {
        console.log(this.state.isModalOpen);
        this.toggle();
        //this.setState({
        //    isModalOpen: true
        //});
        console.log(this.state.isModalOpen);
    }
    onCurrentImageChange(index) {
        this.setState({ currentImage: index });
    }

    closeModal() {
        const myProps = localStorage.getItem('galleryProps');
        if (myProps) {

            this.setState({ galleryProps: JSON.parse(myProps), isModalOpen: false });
        }
    }
    cancelModal() {

        this.setState({ isModalOpen: false });
    }



    lightboxWillClose() {
        clearInterval(this.state.timerId)
        this.setState({ isPlaying: false });
        if (this.state.isFullScreen)
            this.fullScreenRef.fullScreen()

    }


    onAutoPlay() {
        if (!this.state.isPlaying) {
            this.timerID = setInterval(() => this.tick(), this.state.slideInterval);
            this.setState({ timerId: this.timerID })
            this.tick();
        }
        else
            clearInterval(this.timerID)

        this.setState({ isPlaying: !this.state.isPlaying });
    }

    onFullScreenChange(isFullScreen) {
        this.setState({
            isFullScreen
        })
    }


    tick() {

        if (this.state.currentImage < this.state.images.length - 1)
            this._Gallery.gotoNext();
        else
            this._Gallery.gotoImage(0);
    }

    deleteImage() {
        if (window.confirm(`Are you sure you want to delete image number ${this.state.currentImage}?`)) {
            var images = this.state.images.slice();
            images.splice(this.state.currentImage, 1)
            this.setState({
                images: images
            });
        }
    }



    componentDidMount() {
        const myProps = localStorage.getItem('galleryProps');
        if (myProps) {

            this.setState({ galleryProps: JSON.parse(myProps), openModal: false });
        }
        this.populateGalleryGridData();
    } 

    

    onSelectIntervalChange(obj) {
        var newInterval = parseInt(obj.target.value);
        this.setState({ slideInterval: newInterval });

        if (this.state.isPlaying) {
            clearInterval(this.timerID)
            this.timerID = setInterval(() => this.tick(), newInterval);
            this.setState({ timerId: this.timerID })
            this.tick();
        }
    }


    requestOrExitFullScreen() {
        this.fullScreenRef.fullScreen()
    }


    render() {
        const iconStyle = {
            backgroundColor: 'transparent',
            color: 'white',
            cursor: 'pointer',
            marginTop: '5px',
            float: 'left',
            paddingRight: '15px',

        }
        let props = this.state.galleryProps;
        let contents = this.state.loading ?
            <div className="text-center" style={{ height: '100%', marginTop: '25%' }}>
                <div style={{ height: '100px', width: '100px' }} className="spinner-border text-success"></div><h3><em>Henter fotos...</em></h3>
            </div>
            :
            <div style={{
                display: "block",
                minHeight: "1px",
                width: "100%",
                border: "1px solid #ddd",
                overflow: "auto"
            }}>
                <div style={{
                    padding: "2px",
                    color: "#666"
                }}>
                    <h4> <span style={{ float: 'right' }}>
                        <small>
                            <Moment
                                locale="da"
                                format="D. MMMM YYYY">{this.state.gallery[0].Date}</Moment>
                        </small>
                        <FontAwesomeIcon icon={faCog} title="Indstillinger" style={{ paddingLeft: '10px', cursor: 'pointer' }} size="lg" onClick={this.showModal.bind(this)} />
                        <SettingsModal
                            handleOpen={this.state.isModalOpen}
                            handleSubmit={() => this.closeModal()}
                            handleCancel={() => this.cancelModal()}
                            parentUpdate={this.closeModal}
                        />
                    </span>
                        <div dangerouslySetInnerHTML={{ __html: this.state.gallery ? this.state.gallery[0].Title : '' }}></div></h4>
                    <div dangerouslySetInnerHTML={{ __html: this.state.gallery ? this.state.gallery[0].Description : '' }}></div>

                    {this.state.images.length} fotos.</div>
                <Gallery
                    backdropClosesModal={props.backdropClosesModal}

                    images={this.state.images}
                    ref={i => this._Gallery = i}
                    lightboxWidth={props.lightboxWidth}
                    enableLightbox={props.enableLightbox}
                    imageCountSeparator={props.imageCountSeparator}
                    enableImageSelection={props.enableImageSelection}
                    showLightboxThumbnails={props.showLightboxThumbnails}
                    rowHeight={props.rowHeight}
                    lightboxWillClose={this.lightboxWillClose.bind(this)}
                    currentImageWillChange={this.onCurrentImageChange.bind(this)}
                    enableKeyboardInput={props.enableKeyboardInput}
                    slideInterval={props.slideInterval}
                    customControls={[<span key="toggleFullScreen" style={{ color: 'white' }}>
                        <FullScreen key="FullScreen" ref={ref => { this.fullScreenRef = ref }} onFullScreenChange={this.onFullScreenChange.bind(this)}>
                            <span
                                onClick={this.requestOrExitFullScreen.bind(this)}
                                style={iconStyle}>
                                <FontAwesomeIcon fixedWidth size="2x" icon={faExpand} style={{ display: !this.state.isFullScreen ? 'block' : 'none' }} title="Fuld skærm" />
                                <FontAwesomeIcon fixedWidth size="2x" icon={faCompress} style={{ display: this.state.isFullScreen ? 'block' : 'none' }} title="Afslut Fuldskærm" />
                            </span>
                        </FullScreen>

                        <span key="Auto Play"
                            onClick={this.onAutoPlay}
                            style={iconStyle} >
                            <FontAwesomeIcon fixedWidth size="2x" icon={faPlay} style={{ display: this.state.isPlaying ? 'none' : 'block' }} title="Afspil" />
                            <FontAwesomeIcon fixedWidth size="2x" icon={faPause} style={{ display: this.state.isPlaying ? 'block' : 'none' }} title="Pause" />
                        </span>

                    </span>
                    ]}
                />
            </div>
        return (
            <div>
                {contents}
            </div>
        );
    }

    async populateGalleryGridData() {
        const response = await fetch('/api/Gallery/GetGrid/' + this.state.folder);
        console.log('/api/Gallery/GetGrid/' + this.state.folder);

        const data = await response.json();
        this.setState({
            images: data,
            loading: false
        });

        //if (data.length > 0)
        //    this._fillImageInfo(data[0]);

    }
}

