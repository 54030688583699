import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Om } from './components/Om';
import { Gear } from './components/Gear';
import { Show } from './components/show';
import { Vis } from './components/Vis';
import { ShowGrid } from './components/ShowGrid/ShowGrid';
import { Login } from './components/Login/Login';
import './custom.css';

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Route exact path='/' component={Home} />
                <Route path='/om' component={Om} />
                <Route path='/gear' component={Gear} />
                <Route path='/show/:path' component={Show} />
                <Route path='/showGrid/:path' component={ShowGrid} />
                <Route path='/vis/:path' component={Vis} />
                <Route path='/Login' component={Login} />
            </Layout>
        );
    }
}
