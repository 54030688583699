import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneSquare, faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons';
export class Om extends Component {
    static displayName = Om.name;




    render() {
        return (
            <div>
                <h4>Om dette site <small>og alt det med småt</small></h4>

                <p> Ungstrup.net ejes af:</p>
                <address>Erik Lyngsøe<br />
                        Ungstrupvej 1A, Ungstrup<br />
                        8620 Kjelleup.<br />
                    <FontAwesomeIcon fixedWidth icon={faPhoneSquare} />&nbsp;+45 24 80 51 99<br />
                    <FontAwesomeIcon fixedWidth icon={faEnvelopeSquare} />&nbsp;<a href="mailto:webmaster@ungstrup.net">webmaster@ungstrup.net</a><br />

                </address>
                <h4>Formål</h4>
                <p>At gøre mine fotos tilgængelige for de som måtte have lyst at kigge på. Samt for at vedligeholde mit håndværk - Programmering - og holde mig ajour med mit gamle fag.
                    </p>
                <h4>Værktøjer</h4>
                <p>Dette site er lavet med følgende værktøjer:</p>
                <ul>
                    <li style={{ display: 'list-item', listStyle: 'inside' }}>Visual Studio 2019.</li>
                    <li style={{ display: 'list-item', listStyle: 'inside' }}>.Net Core 3.1</li>
                    <li style={{ display: 'list-item', listStyle: 'inside' }}>ReactJS</li>
                </ul>
                <h4>Generelt.</h4>
                <p>Informationer på www.ungstrup.net er af generel karakter. Anvendelse af sidens informationer sker for brugerens eget ansvar og risiko.</p>

                <h4>Ansvar.</h4>
                <p>
                    Erik Lyngsøe påtager sig intet ansvar for indholdet på eksterne hjemmesider,
               der eventuelt måtte linkes til fra denne hjemmeside ligesom Erik Lyngsøe ikke kan stilles til ansvar for fejl eller ændringer på denne hjemmeside.         </p>
                <p> Dette gælder også vedrørende alle øvrige oplysninger meddelt via hjemmesiden.</p>

                <p>Hjemmesiden er beskyttet af lov om ophavsret, og hjemmesiden eller dele heraf må derfor ikke gengives uden tilladelse fra Erik Lyngsøe.</p>
                <h4>Datasikkerhed.</h4>
                <p>ungstrup.net benytter <a href="https://da.wikipedia.org/wiki/HTTPS" rel="noopener noreferrer" target="_blank" title="Læs mere om HTTPS" > https(SSL)</a >,
                dette sikrer at alle oplysninger der sendes mellem brugeren og hjemmesiden er kodet og er derfor umulige for en eventuel hacker at tilgå.
                </p>
                <p>På ungstrup.net kommer din personlige sikkerhed i forreste række.Derfor beder vi ikke om personlige oplysninger, som ikke vedkommer os.
        Vi garanterer samtidig, at dine oplysninger kun vil blive brugt i forbindelse med registrering af ungstrup.nets brugere.</p>
                <p>Dine oplysninger vil under ingen omstændigheder blive videregivet til anden side.</p>
                < h4 > Cookies.</h4 >
                <p>ungstrup.net bruger cookies for at gøre det nemmere, hurtigere og sjovere for dig at besøge på ungstrup.net. Cookies bruges også til at analysere, hvordan vores brugere bruger websitet.< br />
                </p><p>
                    Hvilke cookies bruger ungstrup.net ?</p>
                <ul>
                    <li style={{ display: 'list-item', listStyle: 'inside' }}>1. parts cookies er nødvendigt for at websitet til at fungere.</li>
                    <li style={{ display: 'list-item', listStyle: 'inside' }}>3. parts cookies bruges til analyser og markedsføring.</li>

                    <li style={{ display: 'list-item', listStyle: 'inside' }}>Google Analytics.</li>
                    <li style={{ display: 'list-item', listStyle: 'inside' }}>Google Tag Manager.</li>
                </ul>

                <p>For at få vores website til at fungere bedst muligt lægger vi sommetider små datafiler, såkaldte cookies, på din computer. Det gør de fleste store websites.</p>
                <h4>Hvad er cookies?</h4>
                <p>En cookie er en lille tekstfil, som et website gemmer på din computer eller dit mobiludstyr, når du besøger sitet. På den måde kan websitet huske dine handlinger og indstillinger (såsom login, sprog, skriftstørrelse og andre indstillinger for visning) i en vis periode, så du ikke behøver at foretage indstillingerne, hver gang du går ind på sitet eller bevæger dig fra én side til en anden. </p>
                <h4>Hvordan bruger vi cookies?</h4>
                <div className="alert">
                    <h4>Eksempel:</h4>
                    <div className="alert alert-info">
                        <p>En række af vores sider bruger cookies til at huske:</p>
                        <ul>
                            <li>dine indstillinger for visning såsom kontrastfarve eller skriftstørrelse</li>
                            <li>om du allerede har svaret på en pop-up-undersøgelse, der beder dig svare på om indholdet var nyttigt eller ej (så du ikke bliver bedt om at svare igen)</li>
                            <li>hvor vidt du har accepteret vores brug af cookies på sitet eller ej.</li>
                        </ul>
                        <p>Visse videoklip på vores sider anvender også en cookie for anonymt at indhente statistik om, hvordan du kom frem til siden, og hvilke videoer du har set.</p>
                        <p>Det er ikke strengt nødvendigt at acceptere cookies, for at websitet kan fungere, men det vil give dig en bedre oplevelse, når du bruger sitet. Du kan slette eller blokere cookies, men visse funktioner på sitet vil i så fald muligvis ikke fungere korrekt.</p>
                        <p>De oplysninger, der indhentes med cookies, bruges ikke til at identificere dig personligt, og vi har fuld kontrol over datamønstrene. Vores cookies anvendes ikke til andre formål end de, der er beskrevet her.</p>
                    </div>
                </div>
                <h4>Hvordan kontrollerer du cookies?</h4>
                <p>Du kan kontrollere og/eller slette cookies, som du vil – læs mere på <a href="http://www.aboutcookies.org/" target="_blank" rel="noopener noreferrer nofollow">aboutcookies.org</a>. Du kan slette alle de cookies, der allerede er på din computer, og indstille de fleste browsere til at forhindre dem i at blive lagt på computeren. Hvis du gør dette, skal du dog muligvis selv tilpasse dine indstillinger manuelt, hver gang du besøger et site, og visse tjenester og funktioner vil muligvis ikke fungere.</p>
                <p>Du kan få mere information om cookie lovgivningen på <a href="https://erhvervsstyrelsen.dk/cookie-loven" rel="noopener noreferrer" target="_blank">Erhversstyrelsens hjemmeside</a></p>
                <p>Med venlig hilsen<br />                Erik Lyngsøe</p>
            </div>


        );
    }
}
