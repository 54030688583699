import React, { Component } from 'react';
import Moment from 'react-moment';
import { Button } from 'reactstrap';
import 'moment/locale/da';
import ImageGallery from 'react-image-gallery';
import { Col, Row } from 'react-bootstrap';
import './Show.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faCamera, faChevronRight, faMap, faPlay, faPause, faUser, faBinoculars, faCog, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import Galleries from '../data/Gallery.json';

export class Show extends Component {
    static displayName = Show.name;

    constructor(props) {
        super(props);
        this.state = {
            folder: props.match.params.path,
            gallery: Galleries.filter(function (item) { return item.Folder === props.match.params.path; }),
            loading: true,
            images: [],
            //physicalPathToGallery: 'C:\\inetpub\\wwwroot\\lits.dk\\ClientApp\\build\\galleries\\',
            physicalPathToGallery: 'E:\\Dev\\Gallery\\Gallery\\Gallery\\ClientApp\\public\\galleries\\',
            showThumbnails: localStorage.getItem("showThumbnails") === 'true',
            showBullets: localStorage.getItem("showBullets") === 'true',
            thumbnailPosition: localStorage.getItem("showthumbnailsPosition") != null ? localStorage.getItem("showthumbnailsPosition") : 'left',
            showFullscreenButton: localStorage.getItem("showFullscreenButton") === 'true',
            disableThumbnailScroll: localStorage.getItem("disableThumbnailScroll") === 'true',
            slideOnThumbnailOver: true,
            showGalleryFullscreenButton: localStorage.getItem("showGalleryFullscreenButton") === 'true',
            showPlayButton: localStorage.getItem("showPlayButton") === 'true',
            showGalleryPlayButton: localStorage.getItem("showGalleryPlayButton") === 'true',
            showNav: localStorage.getItem("showNav") === 'true',
            showSettings: localStorage.getItem("showSettings") === 'true',
            showInfo: localStorage.getItem("showInfo") === 'true',
            showInfoCaption: localStorage.getItem("showInfo") === 'true' ? 'Skjul foto info' : 'Vis foto info ',
            showSettingsCaption: 'Indstillinger',
            buttonPlayPauseCaption: "Start slideshow",
            isPlaying: false,

            image: {
                imageTitle: '',
                cameraModel: '',
                iso: '',
                lensModel: '',
                artist: '',
                copyright: '',
                gps: ''
            }

        };
    }


    _onScreenChange(fullscreenElement) {
        this.setState({
            showFullscreenButton: fullscreenElement,
            showNav: fullscreenElement,
            showPlayButton: fullscreenElement
        });
    }

    _onSlide(currentIndex) {

        this._fillImageInfo(this.state.images[currentIndex])
        if (this.state.image.height > this.state.image.width)
            alert("portait");
    }

    _handleButtonFullScreenClick(event) {
        this._productGallery.fullScreen();
    }

    _handleButtonToggleSettings(event) {
        var oldShowSettings = this.state.showSettings
        var text = oldShowSettings ? 'Indstillinger ' : 'Skjul indstillinger';

        this.setState({ showSettings: !oldShowSettings })
        this.setState({ showSettingsCaption: text })
    }

    _handleButtonToggleInfo(event) {
        var oldShowInfo = this.state.showInfo
        var text = oldShowInfo ? 'Vis foto info' : 'Skjul foto info ';

        localStorage.setItem("showInfo", !oldShowInfo);

        this.setState({ showInfo: !oldShowInfo });
        this.setState({ showInfoCaption: text });
    }

    _handleCheckboxChange(state, event) {
        localStorage.setItem(state, event.target.checked)
        this.setState({ [state]: event.target.checked });
    }

    _handleThumbnailPositionChange(event) {
        this.setState({ thumbnailPosition: event.target.value });
        localStorage.setItem("showthumbnailsPosition", event.target.value);
    }

    _handleButtonNavigationClick(action, event) {
        var currentIndex = this._productGallery ? this._productGallery.getCurrentIndex() : 0;
        var length = this._productGallery ? this._productGallery.props.items.length : 0;
        switch (action) {
            case 'next':
                var nextIndex = currentIndex === length - 1 ? 0 : currentIndex + 1;
                this._productGallery.slideToIndex(nextIndex);
                break;
            case 'prev':
                var prevIndex = currentIndex > 0 ? currentIndex - 1 : length - 1;
                this._productGallery.slideToIndex(prevIndex);
                break;
            case 'play':
                this.state.isPlaying ? this._productGallery.pause() : this._productGallery.play();
                this.setState({ buttonPlayPauseCaption: this.state.isPlaying ? "Start slideshow" : "Stop slideshow" });
                this.setState({ isPlaying: !this.state.isPlaying });
                break;
            default:
                break;
        }


    }


    componentWillMount() {

    }


    componentDidMount() {
        //var myState = localStorage.getItem("showState");

        this.populateGalleryData();
    }

    render() {
        let contents = this.state.loading ?
            <div className="text-center" style={{ height: '100%', marginTop: '25%' }}>


                <div style={{ height: '100px', width: '100px' }} className="spinner-border text-success"></div><h3><em>Henter fotos...</em></h3>

            </div>
            :
            <div>
                <h4> <span style={{ float: 'right' }}>
                    <small>
                        <Moment
                            locale="da"
                            format="D. MMMM YYYY">{this.state.gallery[0].Date}</Moment>
                    </small></span>
                    <div dangerouslySetInnerHTML={{ __html: this.state.gallery[0].Title }}></div></h4>

                <Row>
                    <Col lg="3">
                        <ul className='app-buttons'>
                            <li>
                                <div className="text-center">
                                    <Button
                                        title="Forrige"
                                        outline
                                        color="success"
                                        style={{ float: 'left', width: '32%' }}
                                        onClick={() => this._handleButtonNavigationClick('prev', this)}>
                                        <FontAwesomeIcon icon={faChevronLeft} size="2x" />
                                    </Button>
                                &nbsp;
                                <Button title={this.state.buttonPlayPauseCaption}
                                        color="success"
                                        outline

                                        onClick={() => this._handleButtonNavigationClick('play', this)} >
                                        {this.state.isPlaying ? <FontAwesomeIcon icon={faPause} size="2x" /> : <FontAwesomeIcon icon={faPlay} size="2x" />}
                                    </Button>
                                &nbsp;
                                <Button title="Næste"
                                        outline
                                        color="success"
                                        style={{ float: 'right', width: '32%' }}
                                        onClick={() => this._handleButtonNavigationClick('next', this)} >
                                        <FontAwesomeIcon icon={faChevronRight} size="2x" />
                                    </Button>
                                </div>
                            </li>
                            <li>
                                <input type="button"
                                    value="Fuld skærm"
                                    className="btn  btn-outline-success btn-block "
                                    onClick={this._handleButtonFullScreenClick.bind(this)} />
                            </li>
                            <li>
                                <input type="button"
                                    id='buttonToggleInfo'
                                    value={this.state.showInfoCaption}
                                    className="btn btn-outline-success btn-block"
                                    onClick={this._handleButtonToggleInfo.bind(this)} />
                            </li>
                            <li>
                                <input
                                    id='buttonToggleSettings'
                                    type='button'
                                    onClick={this._handleButtonToggleSettings.bind(this)}
                                    className="btn btn-outline-success btn-block"
                                    value={this.state.showSettingsCaption} />

                            </li>
                        </ul>
                        <ul id="ulInfo" className='app-buttons' style={{ display: this.state.showInfo ? 'block' : 'none' }}>
                            <li>
                                <table className="table table-striped table-condensed">
                                    <thead>
                                        <tr>
                                            <td><h5>FotoInfo</h5></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td Title="Titel/Beskrivelse"><FontAwesomeIcon icon={faPencilAlt} size="lg" fixedWidth title="Titel" />&nbsp;    {this.state.image.imageTitle}</td>
                                        </tr>
                                        <tr className={this.state.image.gps != null ? '' : 'd-none'} >
                                            <td title="Vi på Google Maps:" >
                                                <a href={'https://www.google.com/maps/place/' + this.state.image.gps} target="_blank" rel="noopener noreferrer"  title="Vis sted på Google Maps">
                                                    <FontAwesomeIcon icon={faMap} size="lg" fixedWidth />&nbsp; Vis på Google maps</a></td>
                                        </tr>
                                        <tr>
                                            <td title="Taget af" >
                                                <FontAwesomeIcon icon={faUser} size="lg" fixedWidth />&nbsp;  {this.state.image.artist}</td></tr>
                                        <tr>
                                            <td title="Kamera" ><FontAwesomeIcon icon={faCamera} size="lg" fixedWidth />&nbsp; {this.state.image.cameraModel}</td>
                                        </tr>
                                        <tr>
                                            <td title="Ojektiv"><FontAwesomeIcon icon={faBinoculars} size="lg" fixedWidth  />&nbsp; fa {this.state.image.lensModel}</td>
                                        </tr>
                                        <tr>
                                            <td Title="Kameraindstillinger: lukketid, blænde, ISO, Focal length"><FontAwesomeIcon icon={faCog} size="lg" fixedWidth  />&nbsp; {this.state.image.shutterSpeed} · {this.state.image.apture} · ISO: {this.state.image.iso} - {this.state.image.focalLength}</td>
                                        </tr>
                                        <tr className="d-none">
                                            <td>: {this.state.image.iso}</td>
                                        </tr>
                                    </tbody>
                                </table>


                            </li>
                        </ul>

                        <ul id="ulSettings" className='app-buttons' style={{ display: this.state.showSettings ? 'block' : 'none' }}>
                            <li>
                                <input
                                    id='show_thumbnails'
                                    type='checkbox'
                                    onChange={this._handleCheckboxChange.bind(this, 'showThumbnails')}
                                    checked={this.state.showThumbnails}
                                    className="checkbox" />
                                <label htmlFor='show_thumbnails' style={{ cursor: 'pointer' }}>&nbsp;Vis miniaturer</label>
                            </li>
                            <li style={{ display: this.state.showThumbnails ? 'block' : 'none' }}>
                                <div className='app-interval-input-group'>
                                    <span className='app-interval-label'>Miniature Position</span>
                                    <select
                                        className='app-interval-input'
                                        value={this.state.thumbnailPosition}
                                        onChange={this._handleThumbnailPositionChange.bind(this)}
                                    >
                                        <option value='bottom'>Nederst</option>
                                        <option value='top'>Øverst</option>
                                        <option value='left'>Venstre</option>
                                        <option value='right'>Højre</option>
                                    </select>
                                </div>
                            </li>
                            <li>
                                <input
                                    id='show_bullets'
                                    type='checkbox'
                                    onChange={this._handleCheckboxChange.bind(this, 'showBullets')}
                                    checked={this.state.showBullets} />
                                <label htmlFor='show_bullets' style={{ cursor: 'pointer' }}>Vis bullets</label>
                            </li>
                            <li>
                                <input
                                    id='show_fullscreen'
                                    type='checkbox'
                                    onChange={this._handleCheckboxChange.bind(this, 'showFullscreenButton')}
                                    checked={this.state.showFullscreenButton} />
                                <label htmlFor='show_fullscreen' style={{ cursor: 'pointer' }}>Vis Fuldskærms knap</label>
                            </li>
                            <li>
                                <input
                                    id='show_playbutton'
                                    type='checkbox'
                                    onChange={this._handleCheckboxChange.bind(this, 'showPlayButton')}
                                    checked={this.state.showPlayButton} />
                                <label htmlFor='show_playbutton' style={{ cursor: 'pointer' }}>Vis play knap</label>
                            </li>
                            <li>
                                <input
                                    id='show_navigation'
                                    type='checkbox'
                                    onChange={this._handleCheckboxChange.bind(this, 'showNav')}
                                    checked={this.state.showNav} />
                                <label htmlFor='show_navigation' style={{ cursor: 'pointer' }}>Vis navigation</label>
                            </li>
                        </ul>
                    </Col>
                    <Col lg="9">
                        <h6>{this.state.image.imageTitle}</h6>
                        <ImageGallery
                            items={this.state.images}
                            lazyLoad={false}
                            showIndex={true}
                            showPlayButton={this.state.showPlayButton}
                            slideOnThumbnailOver={this.state.slideOnThumbnailOver}
                            ref={i => this._productGallery = i}
                            thumbnailPosition={this.state.thumbnailPosition}
                            showThumbnails={this.state.showThumbnails}
                            disableThumbnailScroll={false}
                            showBullets={this.state.showBullets}
                            showFullscreenButton={this.state.showFullscreenButton}
                            showNav={this.state.showNav}
                            onScreenChange={this._onScreenChange.bind(this)}
                            onSlide={this._onSlide.bind(this)}
                            
                        />
                        <div className="text-center">{this.state.image.imageTitle}</div>

                    </Col>
                </Row>
            </div >


        return (
            <div>
                {contents}

            </div>
        );
    }

    _fillImageInfo(imgInfo) {
        this.setState({
            image: {
                imageTitle: imgInfo.originalTitle,
                cameraModel: imgInfo.model,
                iso: imgInfo.iso,
                lensModel: imgInfo.lensModel,
                artist: imgInfo.artist,
                shutterSpeed: imgInfo.shutterSpeed,
                apture: imgInfo.apture,
                focalLength: imgInfo.focalLength,
                gps: imgInfo.gpsLinkToGoogle,
                height: imgInfo.height, 
                width: imgInfo.width

            },
        })
    }

    async populateGalleryData() {
        const response = await fetch('api/Gallery/getFotos/' + this.state.folder);
        const data = await response.json();
        this.setState({
            images: data,
            loading: false
        });

        if (data.length > 0)
            this._fillImageInfo(data[0]);

    }
}